<template>
  <Layout>

    <div class="align-items-center text-center page-headline mb-3">
      <div class="text-xl-left">
        <h1 class="display-3">{{ $t(`page.${role}.leaderboard`) }}</h1>
      </div>
    </div>

    <div v-if="loading">
      <div class="text-center">
        <b-spinner variant="primary" />
      </div>
    </div>
    <div v-else>
      <b-alert v-if="error" variant="danger" class="mt-3" dismissible :show="Boolean(error)">{{ error }}</b-alert>
      <template v-else>

        <div class="card">
          <div class="card-body">

            <!-- Admin filters -->
            <div v-if="role === 'admin'" class="row mb-md-2 text-center table-filters">
              <div class="col-12 col-xl-auto ml-xl-0 text-xl-left">
                <div class="table-filters-label d-block d-md-inline-flex align-items-center flex-wrap mr-2">
                  <label class="d-inline-flex align-items-center mr-2">
                    <span class="text-nowrap mr-2">{{ $t('form.filter-by-company') }}:</span>
                    <AutocompleteSearchCompany v-model="company" :key="refreshKey" />
                  </label>
                </div>
              </div>
              <div class="col-12 col-xl-auto m-xl-auto flex-xl-grow-1 text-xl-center">
                <div class="table-filters-range">
                  <label class="d-flex align-items-center">
                    <span class="text-nowrap mr-2">{{ $t('form.date-range') }}:</span>
                    <date-picker range v-model="range" :disabled-date="(date) => date >= new Date()"
                      :lang="datepickerConfig" value-type="YYYY-MM-DD" format="YYYY-MM-DD"
                      :placeholder="$t('form.date-range-placeholder')" :disabled="loading" input-class="form-control" />
                  </label>
                </div>
              </div>
              <div class="col-12 col-xl-auto mr-xl-0 text-xl-right">
                <div id="tickets-table_filter" class="dataTables_filter">
                  <label class="d-inline-flex align-items-center">
                    <span class="text-nowrap">{{ $t('form.quantity') }}:</span>
                    <b-form-select v-model="per_page" size="sm" :options="pageOptions"
                      class="form-control form-control-sm ml-2"></b-form-select>
                  </label>
                </div>
              </div>
            </div>

            <!-- Company filters -->
            <div v-if="role === 'company'" class="row mb-md-2 text-center table-filters">
              <div class="col-lg-6 col-md-auto text-md-left">
                <div class="table-filters-range ml-0">
                  <label class="d-flex align-items-center">
                    <span class="text-nowrap mr-2">{{ $t('form.date-range') }}:</span>
                    <date-picker range v-model="range" :disabled-date="(date) => date >= new Date()"
                      :lang="datepickerConfig" value-type="YYYY-MM-DD" format="YYYY-MM-DD"
                      :placeholder="$t('form.date-range-placeholder')" :disabled="loading" input-class="form-control" />
                  </label>
                </div>
              </div>
              <div class="col-lg-6 col-md-auto ml-auto">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    <span class="text-nowrap">{{ $t('form.quantity') }}:</span>
                    <b-form-select v-model="per_page" size="sm" :options="pageOptions"
                      class="form-control form-control-sm ml-2"></b-form-select>
                  </label>
                </div>
              </div>
            </div>

            <div class="table-responsive mb-0">

              <!-- <div>
                <pre>companies: {{ companies }}</pre>
              </div> -->
              <!-- <div>
                <pre>page: {{ page }}</pre>
              </div> -->
              <!-- <div>
                <pre>per_page: {{ per_page }}</pre>
              </div> -->
              <!-- <div>
                <pre>tableData: {{ tableData }}</pre>
              </div> -->
              <!-- <div>
                <pre>sortable: {{ sortable }}</pre>
              </div> -->
              <!-- <div>
                <pre>range: {{ range }}</pre>
              </div> -->
              <!-- <div>
                <pre>sortBy: {{ sortBy }}</pre>
              </div> -->
              <!-- <div>
                <pre>sortDesc: {{ sortDesc }}</pre>
              </div> -->

              <b-table :items="tableData" :fields="tableFields" responsive="sm" :per-page="per_page" :filter="filter"
                :filter-included-fields="filterOn" show-empty :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                :busy.sync="isBusy" @head-clicked="headClicked" :no-local-sorting="true">

                <template #head(client_id)="data">
                  {{ $t('table.id') }}
                </template>
                <template #head(fio)="data">
                  {{ $t('table.fio') }}
                </template>
                <template #head(company_id)="data">
                  {{ $t('table.company_name') }}
                </template>
                <template #head(mid_steps)="data">
                  {{ $t('table.mid_steps') }}
                </template>
                <template #head(sum_steps)="data">
                  {{ $t('table.sum_steps') }}
                </template>
                <template #head(mid_pulse_mid)="data">
                  {{ $t('table.mid_pulse_mid') }}
                </template>
                <template #head(sum_calories)="data">
                  {{ $t('table.sum_calories') }}
                </template>
                <template #head(mid_duration)="data">
                  {{ $t('table.mid_duration') }}
                </template>
                <template #head(sum_duration)="data">
                  {{ $t('table.sum_duration') }}
                </template>

                <template #cell(company_id)="data">
                  {{ companies.filter(c => c.id == data.item.company_id)[0]?.name || "" }}
                </template>

                <template #cell(fio)="data">
                  <router-link :to="`/${role}/users/${data.item.client_id}`">{{ data.item.fio }}</router-link>
                </template>

                <template #empty>
                  <h4 class="text-center">
                    {{ $t('table.no-data') }}
                  </h4>
                </template>
              </b-table>
            </div>
            <div class="row mb-3">
              <div class="col-12 text-center">
                <div class="dataTables_paginate paging_simple_numbers">
                  <b-pagination v-model="page" :total-rows="totalRows" :per-page="per_page"></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

  </Layout>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useGlobalStore } from '@/store/global'
import Layout from "@/views/layouts/main";
import AutocompleteSearchCompany from "@/components/autocomplete-search-company";
import DatePicker from "vue2-datepicker";

export default {
  components: {
    Layout,
    AutocompleteSearchCompany,
    DatePicker,
  },
  metaInfo() {
    return {
      title: this.$t('app.title') + " | " + this.$t(`page.${this.role}.leaderboard`),
    };
  },
  data() {
    return {
      loading: false,
      error: "",
      wait: false,

      company: null,
      companies: [],

      tableData: [],
      totalRows: 0,
      page: 1,
      pageOptions: [25, 50, 100],
      per_page: 25,
      filter: null,
      filterOn: [],
      sortBy: "client_id",
      sortDesc: true,
      isBusy: false,
      _helper: "",

      refreshKey: 0,

      range: [],
      datepickerConfig: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
    };
  },
  created() {
    if (this.$route.query?.range?.length && this.$route.query.range[0] && this.$route.query.range[1]) {
      this.range = [this.$route.query.range[0], this.$route.query.range[1]]
    } else {
      this.defaultRangeMonth();
    }

    // this.paramFromQuery();
    this.loadData();
  },
  computed: {
    // ...mapState(useGlobalStore, ['userRoles']),
    ...mapState(useGlobalStore, {
      owner: 'user',
      userRoles: 'userRoles',
    }),
    role() {
      if (this.userRoles?.includes("a")) {
        return 'admin'
      }

      if (this.userRoles?.includes("c")) {
        return 'company'
      }

      return
    },
    tableFields() {
      let fields = [
        {
          key: "client_id",
          sortable: true,
          class: "cell-id",
        },
        {
          key: "fio",
          sortable: true,
          class: "cell-user",
        },
        {
          key: "company_id",
          sortable: true,
        },
        {
          key: "mid_steps",
          sortable: true,
        },
        {
          key: "sum_steps",
          sortable: true,
        },
        {
          key: "mid_pulse_mid",
          sortable: true,
        },
        {
          key: "sum_calories",
          sortable: true,
        },
        {
          key: "mid_duration",
          sortable: true,
        },
        {
          key: "sum_duration",
          sortable: true,
        },
      ]

      // if company remove - field company_id
      if (this.role === 'company') {
        return fields.filter(field => field.key !== 'company_id')
      }

      return fields
    },
    sortable() {
      return this.tableFields?.map(i => i.sortable && i.key) || []
    },
  },
  methods: {
    ...mapActions(useGlobalStore, ['leadersList',]),
    async setQuery(newQuery, toHistory = false) {
      const query = Object.assign({}, { ...this.$route.query, ...newQuery });

      // delete empty fields
      Object.keys(query).forEach(key => {
        if (query[key] === undefined || query[key] === null || query[key] === '') {
          delete query[key];
        }
      })

      if (toHistory) {
        await this.$router.push({ query })
      } else {
        await this.$router.replace({ query }).catch(err => { }) // or t: new Date().getTime(), - timestamp
      }
    },
    paramFromQuery() {
      // console.log('paramFromQuery...')
      this.page = Number(this.$route.query?.page) || 1
      this.per_page = Number(this.$route.query?.per_page) || 25

      // init company
      // this.company_id = Number(this.$route.query?.company_id) || "" // simple select case   
      // const company_id = Number(this.$route.query?.company_id) || ""

      // if (company_id || company_id === 0) {
      //   // console.log('has company_id: ', company_id)
      //   this.company = this.companies.find(c => c.id === company_id)
      // } else {
      //   this.company = null
      // }

      this.sortBy = this.$route.query?.sort || ""
      if (this.sortBy) {
        if (this.$route.query?.sortd === 'asc') {
          this.sortDesc = false
        }
        if (this.$route.query?.sortd === 'desc') {
          this.sortDesc = true
        }
      }

      this.refreshKey += 1
    },
    async loadData() {
      try {
        this.loading = true;
        this.error = "";

        await this.loadTableData(false);
      } catch (error) {
        console.log("loadData Error: ", error);
        this.error = error
      } finally {
        this.loading = false;
      }
    },
    async loadTableData(silent = true) {
      if (this.wait) {
        return
      }

      this.wait = true
      this.isBusy = true

      // console.log('loadTableData...')
      this.paramFromQuery();

      try {
        if (!silent) {
          this.loading = true;
        }
        this.error = "";

        const company_id = Number(this.company?.id) || ""

        // "day_from": "2023-10-01", "day_to": "2023-10-27", "company_id": 25, "page": 0, "sort": "", "sortd": "a"
        const params = {
          per_page: this.per_page,
          page: this.page,
          sort: this.sortBy,
          sortd: "",
          company_id,
          day_from: "",
          day_to: ""
        }

        if (this.sortBy) {
          if (this.sortDesc) {
            params.sortd = 'd'
          } else {
            params.sortd = 'a'
          }
        }

        if (this.range.length && this.range[0] && this.range[1]) {
          params.day_from = this.range[0]
          params.day_to = this.range[1]
        }

        // console.log('params: ', params)

        const response = await this.leadersList(params);
        this.tableData = response.data.list.list || [];
        this.totalRows = response.data.list.total;
        this.companies = response.data.list.companies || [];

      } catch (error) {
        console.log("loadTableData Error: ", error);
        this.error = error;
      } finally {
        this.loading = false;
        this.wait = false
        this.isBusy = false
      }
    },
    headClicked(column) {
      // console.log('headClicked, column: ', column)
      if (this.sortable.includes(column)) {
        // console.log('1 sortable...')

        if (column === this._helper) {
          if (this.sortDesc) {
            this.sortBy = "";
            this._helper = "";
          } else {
            this.sortBy = column;
            this._helper = column;
          }
        } else {
          this.sortDesc = false;
          this.sortBy = column;
          this._helper = column;
        }

      } else {
        // console.log('2 NOT sortable...')
        this.sortBy = "";
        this._helper = "";
      }
    },
    defaultRangeMonth() {
      let date_today = new Date();

      let first_day_of_the_month = new Date(date_today.getFullYear(), date_today.getMonth(), 1);
      // let last_day_of_the_month = new Date(date_today.getFullYear(), date_today.getMonth() + 1, 0);

      // this.range = ["2023-09-25", "2023-10-01"]
      this.range = [this.$helpers.formatDate_YYYY_MM_DD(first_day_of_the_month), this.$helpers.formatDate_YYYY_MM_DD(date_today)]
    },
    updateSort() {
      if (this.sortBy) {
        this.setQuery({ page: 1, sort: this.sortBy, sortd: this.sortDesc ? 'desc' : 'asc' }, true)
      } else {
        this.setQuery({ page: 1, sort: "", sortd: "" }, true)
      }
    },
  },
  watch: {
    // watch query like nuxt watchQuery
    '$route.query'(q, old) {
      if (
        ((q.page || old.page) && (q.page !== old.page)) ||
        ((q.per_page || old.per_page) && (q.per_page !== old.per_page)) ||
        (this.role === 'admin' && (q.company_id || old.company_id) && (q.company_id !== old.company_id)) ||
        ((q.sort || old.sort) && (q.sort !== old.sort)) ||
        ((q.sortd || old.sortd) && (q.sortd !== old.sortd)) ||
        ((JSON.stringify(q.range) !== JSON.stringify(old.range))) // # compare arrays
      ) {
        this.loadTableData()
      }
    },
    page(page) {
      this.setQuery({ page }, true)
    },
    per_page(per_page) {
      this.setQuery({ page: 1, per_page }, true)
    },
    'company.id'(company_id) {
      this.setQuery({ page: 1, company_id }, true)
    },
    sortBy() {
      this.updateSort()
    },
    sortDesc() {
      this.updateSort()
    },
    range() {
      if (this.range.length && this.range[0] && this.range[1]) {
        this.setQuery({ page: 1, range: this.range }, true)
      } else {
        this.setQuery({ page: 1, range: "" }, true)
      }
    },
  },
};
</script>
<style scoped lang="scss">
.table-filters-range {
  max-width: 440px;
  margin: 0 auto;
}
</style>
